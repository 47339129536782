import React, { Component } from 'react'
import '../app.scss'
import AddWord from '../Components/addWord';
import httpClient from '../httpClient';
import { Button } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '../Components/alert';
import { save2storage } from '../localStorageHelper';
import { IsASCII } from '../utils';


class Keywords extends Component{
    constructor(props){
        super(props);

        let i = 0
        let count = 0
        let limit = window.innerWidth/20
        let new_words = []

        let kwds;

        if (this.props.keywords.length == 0) {
            kwds = [];
        }
        
        else {
            kwds = this.props.keywords.split('\n');
        }
        

        this.state = {
            keywords: [...kwds],
            original_keywords: [...kwds],
            busy: false,
            submitted_non_ascii: false,
        }

        this.delete = this.delete.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.add = this.add.bind(this);
        this.reset = this.reset.bind(this);
        this.saveKeywords = this.saveKeywords.bind(this);
    }
    
    // Send request to save updated keywords
    saveKeywords(keywords){

        let x = keywords.join('\n');

        var myParams = {
            "keywords": x,
        }

        httpClient.post('/api/keywords', myParams).then(
            response => {
            this.setState({
                busy: false
            });

            save2storage(
                "keywords",
                x,
            );
        })

    }

    reset(){
        // Reset to saved words

        let x = this.state.original_keywords;

        this.setState({
            keywords: x,
        });

        this.saveKeywords(x);
    }

    add(word){
        this.setState({
            busy: true,
            submitted_non_ascii: false,
        });
        // Add word to list
        word = word.trim();

        if(this.state.keywords.includes(word)) return

        if (!IsASCII(word)) {

            this.setState({
                submitted_non_ascii: true,
            })

            return;
        }

        var joined = this.state.keywords.concat(word);
        joined.sort();
        this.setState({
            keywords: joined,
            },
        );

        this.saveKeywords(joined);
        
    }

    delete(event, word){

        let x = this.state.keywords.filter(el => el !== word);

        // Filter out word from the list
        this.setState({
            keywords: x,
        });

        this.saveKeywords(x);
    }

    deleteAll(){
        // Filter out word from the list
        this.setState({
            keywords: []
        });
    }

    render(){
        return(
            <div style={{display: "flex", flexDirection: "column", gap: "30px"}}>
                {this.state.keywords.length === 0 ?
                    <div className="vertical-center">

                        <Alert 
                            severity="warning" 
                            style={{
                                margin: "0 30vw 30px 30vw",
                                textAlign: "center"
                            }}
                        >
                            You have no keywords saved, no data will be fetched!
                        </Alert>   

                    </div>
                    :

                    <>
                    

                    {
                        this.state.submitted_non_ascii === true ?

                        <Alert 
                            id="ascii-error-msg"
                            severity="warning" 
                            style={{
                                margin: "0 30vw 30px 30vw",
                                textAlign: "center"
                            }}
                        >
                            Only ASCII keywords are allowed!
                        </Alert>   
                        :
                        <></>
                    }
                    

                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap"}}>
                        <br/>
                        {
                            this.state.keywords.map((word, index) => 
                                word === "\n"?
                                <p></p>
                                :
                                <Chip
                                    id={word}
                                    key={word + '_word_element'}
                                    label={word}
                                    size="medium"
                                    variant="filled"
                                    color="default"
                                    onDelete={event => this.delete(event, word)}
                                    style={{
                                        background: "#e0e0e0",
                                        borderRadius: "20px",
                                        margin: "10px 10px 10px 10px"
                                    }}
                                />
                            )
                        }
                    </div>

                    </>
                }
                        <AddWord
                            add={this.add}
                            busy={this.state.busy}
                        />
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            wrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "20px 20px"
                            }}
                        >

                            {/*
                                <Button id="save-keywords-btn" onClick={this.saveKeywords} variant="contained">Save</Button>
                            */}
                            
                            {/*<Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                id="save-keywords-btn"
                                variant="contained"
                                //disabled={this.state.busy}
                                onClick={this.saveKeywords}
                                >
                                Save
                                </Button>
                                {this.state.busy && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    color: "blue",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                                )}
                                </Box>*/}
                            
                            <Button id="clear-all-keywords-btn" onClick={this.deleteAll} variant="contained">Clear All Keywords</Button>
                            { this.state.keywords.sort().join(',') === this.state.original_keywords.sort().join(',') ?
                                <React.Fragment/>
                                :
                                <Button id="reset-keywords-btn" onClick={this.reset} variant="contained">Reset</Button>
                            }
                        </div>
                    </div>

        )
    }
}

export default Keywords;