import { Link } from "react-router-dom";


const NotFound = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <h2>Sorry</h2>
            <p>This page cannot be found.</p>
            <Link to="/">Back to the homepage...</Link>
        </div>
    );
}

export default NotFound;