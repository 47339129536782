import { useState } from "react";


const Labeler = (props) => {

    const type = props.type;
    const [voted, setVoted] = useState(false);

    const vote = (label) => {
        setVoted(true);

        props.addVote(label);
    }

    const removeVote = () => {
        setVoted(false);

        props.removeVote();
    }

    return (
        <div
            className="post__body-icons"
            style={{
                margin: "0 auto"
            }}
        >

            {
                voted === false ?
                    
                    <div>
                        {
                            type === "recommendations" ?

                            <span id="like-btn" onClick={() => vote(1)} className="post__body-icon">👍 </span>

                            :

                            <></>
                        }
                        
                        <span id="dislike-btn" onClick={() => vote(0)} className="post__body-icon">👎 </span>
                    </div>

                :

                <span onClick={() => removeVote()} className="post__body-icon reverse-vote">Reverse Vote </span>

            }
                        
        </div>
    )
};

export default Labeler;