import React, { Component } from 'react'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField';

import PostList from '../Components/list';

import { Button } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '../Components/alert'
import { save2storage } from '../localStorageHelper';

import SubmitPapers from '../Components/submit_papers';
import InvalidPapers from '../Components/invalid_papers';
import httpClient from '../httpClient';


function MyPapers(props) {

    const [papers, setPapers] = useState(props.papers);

    let x;

    if (props.invalid_papers == '') {

        x = [];
    }

    else {

        x = props.invalid_papers.split('\n');
    }

    const [invalid_papers, setInvalidPapers] = useState(x);

    const [busy, setBusy] = useState(false);

    const [user, setUser] = useState(null);
    const history = useNavigate();

    // Is this useEffect even necessary???
    useEffect(()=>{

    }, [papers, invalid_papers])

    const submitURLs = (urls) => {

        setBusy(true);

        let params = {
            "urls": urls,
        };

        httpClient.post(
            '/api/papers_submit',
            params,
        ).then(

            response => {

                setPapers(response.data.papers);
                setInvalidPapers(response.data.invalid_papers.split('\n'));
            }

        ).catch(
            error => {

            }            
        )
        .finally(() => {
            setBusy(false);
        })

        // TODO !!!!!

        localStorage.removeItem("papers")

    }
 
  // Need to split this up into numerous components...

  return (

        // Flex Row Aligned

        busy === true ? 

        <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress />
        </Box>

        :

        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                height: "50vh"
            }}
        >

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    alignItems: "center",
                    maxWidth: "50vw",
                    gap: "20px",
                    margin: "0 auto"
                }}
            >

                <SubmitPapers
                    submitURLs={submitURLs}
                />

                {
                    true ?

                        <InvalidPapers 
                        invalid_papers={invalid_papers}
                    />

                    :

                    <></>
                }
                

            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: "100px 0px 0px 0px",
                    maxWidth: "100%",
                    padding: "0px 150px 0px 150px"
                }}
            >

                <PostList
                    posts={papers}
                    source_name={"my papers"}
                    after_date={null}
                    before_date={null}
                    filter_words={null}
                    isFilterKeywords={null}
                    type={"papers"}
                />

            </div>
            

        </div>

        
    
    );
}

export default MyPapers;
