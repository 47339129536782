import React, { Component } from 'react'
import '../app.scss';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import CategoryList from './categoryList'

class Source extends Component{
    constructor(props) {
        super(props);
     
        this.state = { 
            source_name: this.props.source_name,
            num_sources: this.props.num_sources,
            enabled: this.props.enabled,
            credentials: this.props.credentials,
            use_token: this.props.use_token,
            username: this.props.username,
            password: this.props.password,
            token: this.props.token,
            categories: this.props.categories,
            category_map: this.props.category_map,
        };

        this.modifyEnabled = this.modifyEnabled.bind(this)
        this.modifyUsername = this.modifyUsername.bind(this)
        this.modifyPassword = this.modifyPassword.bind(this)
        this.modifyToken = this.modifyToken.bind(this)
        this.modifyCategory = this.modifyCategory.bind(this)

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleTokenChange = this.handleTokenChange.bind(this);
    }

    modifyEnabled(source_name){
        this.props.modifyEnabled(source_name)
    }

    modifyUsername(source_name, username){
        this.props.modifyUsername(source_name, username)
    }

    modifyPassword(source_name, password){
        this.props.modifyPassword(source_name, password)
    }

    modifyToken(source_name, token){
        this.props.modifyToken(source_name, token)
    }

    modifyCategory(category) {
        this.props.modifyCategory(category)
    }

    handleUsernameChange(event){ 
        this.setState({username: event.target.value})
        this.modifyUsername(this.state.source_name, event.target.value)
    }

    handlePasswordChange(event){ 
        this.setState({password: event.target.value})
        this.modifyPassword(this.state.source_name, event.target.value)
    }

    handleTokenChange(event){
        this.setState({token: event.target.value})
        this.modifyToken(this.state.source_name, event.target.value)
    }

    // Show credential fields (username and password) for relevant sources, and token for Medium
    displayCreds(){
        if (!this.state.credentials) return
        return (
            <React.Fragment>
                { this.state.use_token ?
                    <form className="form-group" style={{padding: "5px 5px 5px 5px"}}>
                        <label htmlFor={this.state.source_name + "_token"}>Token</label>
                        <input 
                            className="form-control"
                            type="password"
                            key={this.state.source_name + "_token"} 
                            id={this.state.source_name + "_token"} 
                            name="token"
                            placeholder="Token"
                            style={{resize: "none"}}
                            onChange={this.handleTokenChange}
                            autoComplete="new-password"
                            defaultValue={this.state.token}
                        />
                    </form>
                    :
                    <form className="form-group" style={{padding: "5px 5px 5px 5px"}}>
                        <label htmlFor={this.state.source_name + "_username"}>Username</label>
                        <input 
                            className="form-control"
                            key={this.state.source_name + "_username"} 
                            type="text"
                            id={this.state.source_name + "_username"} 
                            name="username" 
                            placeholder="Username"
                            style={{resize: "none"}}
                            onChange={this.handleUsernameChange}
                            autoComplete="username"
                            defaultValue={this.state.username}
                        />
                        {/* 
                        <label htmlFor={this.state.source_name + "_password"}>Password</label>
                        <input 
                            className="form-control"
                            type="password"
                            key={this.state.source_name + "_password"} 
                            id={this.state.source_name + "_password"} 
                            name="password" 
                            placeholder="Password"
                            style={{resize: "none"}}
                            onChange={this.handlePasswordChange}
                            autoComplete="new-password"
                            defaultValue={this.state.username}
                        />
                        */}
                        
                    </form>
                }
            </React.Fragment>
        );
    }

    render(){
        return(
            <div className="setting" style={{width : Math.min(100.0/this.state.num_sources, 100) + "%"}}>
                <div className="list">
                    <div className="vertical-center">
                        <input key={this.state.source_name + "_enabled"} 
                            name={this.state.source_name + "_enabled"} 
                            id={this.state.source_name + "_enabled"} 
                            type="checkbox" 
                            defaultChecked={this.state.enabled}
                            onChange={this.modifyEnabled.bind(this, this.state.source_name)}/>    
                        <label htmlFor={this.state.source_name + "_enabled"}> {this.state.source_name} </label>
                    </div>
                    {this.displayCreds()}
                    { this.state.source_name === "arxiv" ?
                        <CategoryList
                            categories={this.state.categories}
                            category_map={this.state.category_map}
                            modifyCategory={this.modifyCategory}
                        /> :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default Source;