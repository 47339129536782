import path from 'path';
import dotenv from 'dotenv';
dotenv.config({ path: path.join(__dirname, '../.env') });

function GetEnvironmentVar(varname, defaultvalue)
{
    let x = process.env;

    var result = process.env[varname];
    if(result!=undefined)
        return parseFloat(result);
    else
        return defaultvalue;
}

function isProduction() {

    let x = process.env["NODE_ENV"];

    if (x == "production") {
        return true;
    }

    return false;
}

function getLocalStorage(item) {
    /*
    Item in local storage is saved as an object

    {
        "data": ...,
        "timestamp": ...
    }
    
    If there item is saved and the timestamp is less than 10 minutes old,
    then we return the data

    Otherwise we remove the item from local storage (since it is stale)
    and return null

    */

    let item_string = localStorage.getItem(item);

    if (item_string == null) {
        return null;
    }

    let item_object = JSON.parse(item_string);

    let timestamp = item_object["timestamp"];

    let elapsed_minutes = milliseconds2minutes(Date.now() - timestamp);

    let time_limit;

    if (isProduction()) {
        time_limit = 10;
    }

    else {
        time_limit=0.2;
    }

    if (elapsed_minutes > time_limit) {
        localStorage.removeItem(item);
        return null;
    }

    // Otherwise Return the Data
    return item_object["data"];

}


function save2storage(name, value) {

    let x = {
        data: value,
        timestamp: Date.now()
    }

    x = JSON.stringify(x);

    localStorage.setItem(
        name,
        x
    )

    return;
}


function milliseconds2minutes(x) {

    let mins  = (
        x/1000
    )/60;

    return mins;
}

export {getLocalStorage, save2storage};