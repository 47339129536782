import { Chip } from "@material-ui/core";

export default function CustomChip(props) {

    let style;
    
    if (props.enabled) {
        style={
            background: "#e0e0e0",
            borderRadius: "20px",
            margin: "10px 10px 10px 10px"
        }
    }

    else {
        style={
            background: "white",
            borderStyle: "solid",
            borderColor: "#e0e0e0",
            borderRadius: "20px",
            margin: "10px 10px 10px 10px"
        }
    }
    

    return (

            <Chip 
                label={props.word} 
                onClick={(event) => props.editFilter(event, props.word)} 
                style={style}
            />        
        
    );
}

