import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import CardMedia from '@mui/material/CardMedia';

import RecommendationsLabellingVideo from '../assets/media/recommendations_and_labelling.mp4';
import KeywordsVideo from '../assets/media/keywords.mp4';
import RecommendationsKeywordsDatesVideo from '../assets/media/recommendations_filter_keywords_dates.mp4';
import SettingsVideo from '../assets/media/settings.mp4';
import LikesVideo from '../assets/media/likes.mp4';
import PapersVideo from '../assets/media/papers.mp4';


const steps = [
  {
    title: "Recommendations Page",
    video: RecommendationsLabellingVideo,
    description: `The Recommendations Page shows you all new recommendations for each source you have enabled.
                  You can label each post with a thumbs-up if you want to see more posts like this or a thumbs-down 
                  to indicate you don't want to see more posts like this.`,
  },
  {
    title: "Keywords Page",
    video: KeywordsVideo,
    description: `You can select a list of keywords for which you would like to see from each source.`,
  },
  {
    title: "Filtering Recommendations",
    video: RecommendationsKeywordsDatesVideo,
    description: `You can also filter your recommendations by your saved keywords and by date.`,
  },
  {
    title: "Settings Page",
    video: SettingsVideo,
    description:
      'The Settings Page allows you to select what sources you would like to see recommendations for.',
  },
  {
    title: "Likes Page",
    video: LikesVideo,
    description: `The Likes Page shows you all of the posts you gave a thumbs-up to from the Recommendations Page.
                  You can also give them a thumbs down in case you no longer wish to see posts like these.`,
  },
  {
    title: "Papers Page",
    video: PapersVideo,
    description: `The Papers Page allows you to upload a list of Arxiv.org links for which you would like to see 
                  similar recommendations.`,
  },
  
];

export default function TextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep(
      (prevActiveStep) => {
        let x = prevActiveStep + 1
        if (x > maxSteps - 1) {
          return 0;
        }

        else {
          return x;
        }
      }
    );
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => {
          let x = prevActiveStep - 1;
          if (x < 0) {
            return maxSteps - 1;
          }

          else {
            return x;
          }
      }
    );
  };

  return (
    <Box sx={{ padding: "40px", flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: "column",
          alignItems: 'center',
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: "20px"
                }}
            >
                {steps[activeStep].title}
            </Typography>
            <Typography>
                {steps[activeStep].description}
            </Typography>
            <CardMedia
                sx={{
                    maxWidth: "90%",
                    marginTop: "10px",
                }}
                component="video"
                autoPlay
                loop
                src={steps[activeStep].video}
            />
        
      </Paper>
      
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button 
            size="small" 
            onClick={handleBack} 
            
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}