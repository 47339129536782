import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress } from "@material-ui/core";
import httpClient from "../httpClient";
import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { useSearchParams } from 'react-router-dom';


export default function EmailConfirmation(props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get("token");

    const [loading, setLoading] = useState(true);
    const [validToken, setValidToken] = useState(false);
    const [message, setMessage] = useState("");
  
    useEffect(() => {

        if (token != null) {

            let params = {
                token: token,
            };
        
            httpClient.get(
                '/api/email_confirmation',
                {
                    params: params
                },
            )
            .then(response => {
        
                let status = response.data.status;
        
                if (status == "success") {
        
                    setValidToken(true);
                }
        
                else {
                    setValidToken(false);
                    setMessage(
                        response.data.message
                    );
                }
        
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            })
        }

        else {
            setValidToken(false);
            setMessage(
                "The token provided is invalid"
            )
            setLoading(false);
        }
        

    }, [])



    return (
      <Box
        style={{
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            gap: "20px",
        }}
      >

        { loading === true ?

            <CircularProgress />

            :
            <>
            <Typography variant="h5">Account Confirmation</Typography>
            {
                validToken === true ?
                    <>
                        <Typography id="success-msg">
                            Your Account Has Been Activated!
                        </Typography>
                        <Link href="/login" variant="body2">
                            {"Click here to login"}
                        </Link>
                    </>
                            :
                <Typography id="invalid-token-msg">
                    {message}
                </Typography>
            }
            </>

        }  
     
      </Box>
    );
  }