import { Component } from 'react'
import '../app.scss'
import Labeler from './labeler';
import httpClient from '../httpClient';

var type2uri_labelProcess = {
    'recommendations': '/api/process_labels',
    'likes': '/api/process_labels',
    'papers': '/api/papers_label',
};

class Post extends Component{

    constructor(props) {
        super(props);
        this.state = {
            source_name: this.props.source_name,
            title: this.props.title,
            link: this.props.link,
            id: this.props.id,
            oembed: this.props.oembed,
            html: this.props.html,
            isVoted: false,
            voteSelection: null,
            type: this.props.type,
            uri: type2uri_labelProcess[this.props.type],
        }
        this.addVote = this.addVote.bind(this);
        this.removeVote = this.removeVote.bind(this);
    }

    
    componentDidMount() {
        if (this.state.source_name === "twitter") {
            if (window.twttr) {
                window.twttr.widgets.load();
              }
            //window.twttr.widgets.load()
        }
    }

    componentDidUpdate() {
        if (this.state.source_name === "twitter") {
            if (window.twttr) {
                window.twttr.widgets.load();
              }
            //window.twttr.widgets.load()
        }
    }

    getColor() {
        if (this.state.voteSelection == null) {
            return null
        }

        else if (this.state.voteSelection == 0) {
            return "red"
        }

        else if (this.state.voteSelection == 1) {
            return "green"
        }
    }


    addVote (label) {

        this.setState({voteSelection: label})

        var params = {
            id: this.state.id,
            label: label,
        }

        httpClient.put(
            this.state.uri,
            params
        ).then(response => {

            this.props.add_n_labelled(1);

        }).catch(error => {
            console.error(error)
        })
        .finally(() => {
            this.setState({
                busy: false
            });
        })

        localStorage.removeItem(
            this.state.type
        );

    }

    removeVote() {

        this.setState(
            {voteSelection: null}
        )

        let label = 1;

        if (this.state.type == "recommendations") {
            label = null;
        }

        var params = {
            id: this.state.id,
            label: label,
        }

        httpClient.put(
            this.state.uri,
            params
        ).then(response => {

            this.props.add_n_labelled(-1);

        }).catch(error => {
            console.error(error)
        })
        .finally(() => {
            this.setState({
                busy: false
            });
        })

        localStorage.removeItem(
            this.state.type
        );

    }

    // Display post with a upvote/downvote button
    render(){
        return(

            <div
            id={this.state.id}
            className="post" 
            style={{
                backgroundColor: this.getColor(),

                width: this.state.source_name == "youtube" ? "400px" : "600px",
                minWidth: "600px",
                height: "100%",
                overflow: "scroll"
            }}
            >
                <div className="post__header">

                </div>
                <div className="post__body" 
                style={{
                    display: 'flex', 
                    flexDirection: 'column',
                    overflow: 'scroll'
                }}
                >
                    <div dangerouslySetInnerHTML=
                            {{
                                __html: this.state.html
                            }}
                            style={{
                                width: '100%',
                                backgroundColor: 'white'
                            }}
                    />
                    
                </div>

                <Labeler
                    addVote={this.addVote}
                    removeVote={this.removeVote}
                    type={this.props.type}
                />
    
            </div>
        )
    }
}

export default Post;