import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import httpClient from '../httpClient';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CircularProgress } from "@material-ui/core";


export default function ForgotPassword(props) {
  
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState("");

    const [doneSubmit, setDoneSubmit] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect( () => {

        if (doneSubmit) {
            
            setLoading(false);
        }

    }, [doneSubmit])


    function submit() {

        setLoading(true);

        let params = {
            email: email,
        }

        httpClient.post(
            '/api/forgot_password',
            params,
        ).then(
            response => {
                // Anything to do here?
        }).catch(
            error => {
            console.log(error);
        })
        .finally(() => {
            setDoneSubmit(true);
        })
    }
  
    return (
        <Box 
            style={{
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
            }}
        >
        { loading === true ?

            <CircularProgress />

            :
            <>
                <Typography 
                    id="title"
                    style={{
                        margin: "0px 0px 10px 0px",
                    }} 
                    variant="h5"
                >
                    Forgot Password
                </Typography>

                {
                    doneSubmit === true ?
                        <Typography>
                            If an account matching this email address exists, an email will be sent to reset the password.
                        </Typography>
                    :
                        <>
                        <Typography>Please enter the email address for the account for which you forgot your password.</Typography>
                        <br></br>
                        <TextField id="email-textfield" label="Email Address" variant="outlined"  onChange={(event) => {setEmail(event.target.value.trim())}}/>
                        <br></br>
                        <Button id="submit-btn" variant="contained" onClick={submit}>Submit</Button>
                        </>
                    
                }
            </>

        }
        </Box>

    );
  }