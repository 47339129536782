import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import httpClient from '../httpClient';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@material-ui/core';
import Link from '@mui/material/Link';


export default function ResetPassword(props) {

    const [searchParams, setSearchParams] = useSearchParams();
  
    const navigate = useNavigate();
  
    // Can this be not present?
    const token = searchParams.get("token");

    const [loading, setLoading] = useState(true);
    const [validToken, setValidToken] = useState(false);
    const [message, setMessage] = useState("");
    const [doneSubmit, setDoneSubmit] = useState(false);

    const [errors, setErrors] = useState({
        password1: "",
        password2: ""
    });

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    useEffect(() => {

        if (token != null) {

            let params = {
                token: token,
            };
        
            httpClient.get(
                '/api/email_confirmation',
                {
                    params: params
                },
            )
            .then(response => {
        
                let status = response.data.status;
        
                if (status == "success") {
        
                    setValidToken(true);
                }
        
                else {
                    setValidToken(false);
                    setMessage(
                        response.data.message
                    );
                }
        
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            })
        }

        else {
            setValidToken(false);
            setMessage(
                "The token provided is invalid"
            )
            setLoading(false);
        }
        

    }, [])

    useEffect(() => {

        let params = {
            token: token,
        };
    
        if (token != null) {

            httpClient.get(
                '/api/reset_password',
                {
                    params: params
                },
            )
            .then(response => {
        
                let status = response.data.status;
        
                if (status == "success") {
        
                    setValidToken(true);
                }
        
                else {
                    setValidToken(false);
                    setMessage(
                        response.data.message
                    );
                }
        
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            })

        }

        else {
            setValidToken(false);
            setMessage(
                "The token provided is invalid"
            );
            setLoading(false);
        }
        

    }, [])

    function submit() {

        const v = validate();

        if (!Object.values(v).every(x => x == "")) {
          setErrors(v);
        }

        else {
            let params = {
                token: token,
                password: password1,
            }
    
            httpClient.put(
                '/api/reset_password',
                params,
            ).then(
                response => {
                    // Anything to do here?
            }).catch(
                error => {
                console.log(error);
            })
            .finally(() => {
                setDoneSubmit(true);
            })
        }

        return;
        
    }

    const validate = () => {
        let errors = {};
  
        if (password1 == "") {
          errors.password1 = "Must not be empty"
        }
  
        else if (password1.length < 9) {
          errors.password1 = "Must be greater than 8 characters"
        }
  
        else {
          errors.password1 = "";
        }
  
        if (password2  != password1) {
          errors.password2 = "Passwords must match"
        }
  
        else {
          errors.password2 = "";
        }
  
        return errors;
  
      }

  
    return (
        <Box 
            style={{
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                justifyItems: "center",
                gap: "20px",
            }}
        >
        { loading === true ?

            <CircularProgress />

            :
            <>
                <Typography variant="h5">Reset Password</Typography>
                <>
                {
                    validToken === true ?
                        <>
                            {
                                doneSubmit == true ?

                                <>
                                    <Typography id="successful-submit-msg">
                                        Your Password Has Been Updated
                                    </Typography>
                                    <Link href="/login" variant="body2">
                                        {"Click here to login"}
                                    </Link>
                                </>
                                :

                                <>
                                    <TextField
                                        error={errors.password1 !== ""}//{password1 !== "" && password1.length < 8}
                                        helperText={errors.password1}//{(password1 !== "" && password1.length < 8) ? "Password must contain at least 8 characters": ""}
                                        required
                                        //fullWidth
                                        name="password1"
                                        label="New Password"
                                        type="password"
                                        id="password1"
                                        autoComplete="new-password"
                                        onChange={(event => setPassword1(event.target.value))}
                                    />

                                    <TextField
                                        error={errors.password2 !== ""}//{password1 !== password2}
                                        helperText={errors.password2}//{password1 !== password2 ? "Passwords must match" : ""}
                                        required
                                        //fullWidth
                                        name="password2"
                                        label="Re-type New Password"
                                        type="password"
                                        id="password2"
                                        autoComplete="new-password"
                                        onChange={(event => setPassword2(event.target.value))}
                                    />
                                    <Button id="submit-btn" variant="contained" onClick={submit}>Submit</Button>
                                </>


                            }
                        </>

                    :
                    <Typography id="invalid-token-message">{message}</Typography>
                }
                </>
            </>
                



        }
        </Box>

    );
  }