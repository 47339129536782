import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {useState} from "react";
import { useNavigate } from "react-router-dom";
import httpClient from '../httpClient';
import isEmail from 'validator/lib/isEmail';
import Alert from '../Components/alert'


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {

    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const token = sessionStorage.getItem("token");
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [signupFailed, setSignupFailed] = useState(false);
    const [signUpFailureMsg, setSignUpFailureMsg] = useState("");
    const [signUpSuccessful, setSignUpSuccessful] = useState(false);

    const [errors, setErrors] = useState({
      firstName: "",
      lastName: "",
      email: "",
      password1: "",
      password2: ""
    });


    const validate = () => {
      let errors = {};

      /* First Name Checks */
      if (firstName == "") {
        errors.firstName = "Must not be empty";
      }

      else if (firstName.length >= 20) {
        errors.firstName = "Must be less than 20 characters";
      }

      else {
        errors.firstName = "";
      }

      /* Last Name Checks */
      if (lastName == "") {
        errors.lastName = "Must not be empty"
      }

      else if (lastName.length >= 20) {
        errors.lastName = "Must be less than 20 characters"
      }

      else {
        errors.lastName = "";
      }

      /* Last Name Checks */
      if (!isEmail(email)) {
        errors.email = "Not Valid"
      }

      else {
        errors.email = "";
      }

      /* Last Name Checks */
      if (password1 == "") {
        errors.password1 = "Must not be empty"
      }

      else if (password1.length < 9) {
        errors.password1 = "Must be greater than 8 characters"
      }

      else {
        errors.password1 = "";
      }

      /* Last Name Checks */
      if (password2  != password1) {
        errors.password2 = "Passwords must match"
      }

      else {
        errors.password2 = "";
      }

      return errors;

    }





    const handleSubmit = (event) => {
      /*  
      event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
      */
        /* Prevents Auto Reload of Page */
        event.preventDefault();

        setSignupFailed(false);
        setSignUpSuccessful(false);

        const v = validate();

        if (!Object.values(v).every(x => x == "")) {
          setErrors(v);
          setSignupFailed(true);
        }
        /*
        let cond = (
          (firstName.length > 20)
          ||
          (lastName.length > 20)
          ||
          (!isEmail(email))
          ||
          (password1.length < 8)
          ||
          (password1 !== password2)
        )

    if (cond) {
      setSignupFailed(true);
    }
    */
    else {
      
        var params = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password1: password1,
          password2: password2,
        }

        httpClient.post("/auth/sign-up", params)
            .then(resp => {
                if (resp.status == 200)  {
                    //alert('record has been created successfully!', 'success')
                    setSignUpSuccessful(true);
                    setSignupFailed(false);
                    setErrors({
                      firstName: "",
                      lastName: "",
                      email: "",
                      password1: "",
                      password2: ""
                    })
                    event.target.reset();
                    //history.push("/login")
                    return resp.json()
                }
                else {
                  //alert("Error");
                  setSignupFailed(true);
                }
            })
            .catch(error => {
              // For some reason I had to add this if statement otherwise I got an error
              // even when I get a 200 status
                if (error.response == null) {
                  return;
                }
                console.error("There was an error!", error.response)
                setSignupFailed(true);
                setSignUpFailureMsg(error.response.data.reason);
            })
      }

    
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {
            signupFailed === true ?
            <Alert 
              severity="error" 
              style={{margin: "0px 0px 30px 0px"}}
            >
              {
                signUpFailureMsg === "" ? 
                "Entered Information is Incorrect Format"
                :
                signUpFailureMsg
              }
            </Alert>
            
            :
            <></>
          }
          {
            signUpSuccessful === true ?
            <Alert
              severity="success"
              style={{margin: "0px 0px 30px 0px"}}
            >

              Sign-Up Was Successful! Please login with your new credentials.

            </Alert>
            :
            <></>

          }
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.firstName !== ""}//{firstName.length > 20}
                  helperText={errors.firstName}//{firstName.length > 20 ? "Must be less than 20 characters": ""}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(event => setFirstName(event.target.value))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.lastName !== ""}//{lastName.length > 20}
                  helperText={errors.lastName}//{lastName.length > 20 ? "Must be less than 20 characters": ""}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(event => setLastName(event.target.value))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.email !== ""}//{email != "" && !isEmail(email)}
                  helperText={errors.email}//{(email != "" && !isEmail(email)) ? "Improper email format": ""}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event => setEmail(event.target.value))}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.password1 !== ""}//{password1 !== "" && password1.length < 8}
                  helperText={errors.password1}//{(password1 !== "" && password1.length < 8) ? "Password must contain at least 8 characters": ""}
                  required
                  fullWidth
                  name="password1"
                  label="Password"
                  type="password"
                  id="password1"
                  autoComplete="new-password"
                  onChange={(event => setPassword1(event.target.value))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.password2 !== ""}//{password1 !== password2}
                  helperText={errors.password2}//{password1 !== password2 ? "Passwords must match" : ""}
                  required
                  fullWidth
                  name="password2"
                  label="Re-type Password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                  onChange={(event => setPassword2(event.target.value))}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/*
          <Copyright sx={{ mt: 5 }} />
        */}
      </Container>
    </ThemeProvider>
  );
}