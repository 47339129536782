import { Component } from 'react'
import '../app.scss'
import '../Styles/buttons.scss'
import UserContext from '../Contexts/userContext'

class CategoryList extends Component{
    static contextType = UserContext

    constructor(props) {
        super(props);

        this.state = {
            categories: this.props.categories,
            category_map: this.props.category_map
        };

        this.modifyCategory = this.modifyCategory.bind(this);
    }

    modifyCategory(category) {
        this.props.modifyCategory(category)
    }

    render(){
        return(
            <div style={{maxHeight: 500, overflow: 'auto'}}>
                {Object.entries(this.state.categories).map(([category, enable]) =>
                    <div className="list" style={{backgroundColor: "lightgrey"}}>
                        <div className="vertical-center">
                            <input key={category}
                                name={category} 
                                id={category} 
                                type="checkbox"
                                defaultChecked={enable}
                                onChange={this.modifyCategory.bind(this, category)}
                            /><br/>   
                            <label htmlFor={category}> <p>{category}</p> <p>{"(" + this.state.category_map[category] + ")"}</p> </label>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default CategoryList