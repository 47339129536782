import React, { Component } from 'react'
import {useState} from "react";
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { render } from '@testing-library/react';

import httpClient from '../httpClient';

const InvalidPapers = (props) => {

    const [invalidPapers, setInvalidPapers] = useState(props.invalid_papers);
    const [filteredInvalidPapers, setFilteredInvalidPapers] = useState(props.invalid_papers);
    const [invalidAltered, setinvalidAltered] = useState(false);

    const deleteLink = (event, index) => {

        let x = filteredInvalidPapers.filter((v, idx) => idx != index);

        setinvalidAltered(true);

        setFilteredInvalidPapers(x);

        send2server(x);

        return;

    }

    const reset = () => {

        setFilteredInvalidPapers(invalidPapers);

        send2server(invalidPapers);

        setinvalidAltered(false);

    }


    const send2server = (invalid_papers) => {

        let params = {
            "invalid_urls": invalid_papers.join('\n'),
        }

        httpClient.put(
            '/api/invalid_papers_save',
            params,
        ).then(
            response => {
                // Anything to do here?
            }
        ).catch(
            error => {
                console.error(error)
            }
        )

        localStorage.removeItem("papers")


    }


    const save = () => {

        setInvalidPapers(filteredInvalidPapers);

        let params = {
            "invalid_urls": filteredInvalidPapers.join('\n'),
        }

        httpClient.put(
            '/api/invalid_papers_save',
            params,
        ).then(
            response => {
                // Anything to do here?
            }
        ).catch(
            error => {
                console.error(error)
            }
        )

        localStorage.removeItem("papers")


    }

    return (
        <div
            style={{
                width: "25vw",
                maxHeight: "30vh",
            }}
        >

                <div 
                    style={{
                        display: "flex", 
                        flexDirection: "column", 
                        overflow: "scroll",
                        maxHeight: "inherit",
                        padding: "30px",
                        border: "5px solid #000",
                        gap: "5px",
                        borderColor: "gray",
                        borderRadius: "10px",
                        scrollPaddingTop: "20px",
                        alignItems: "center"

                }}>
                <span 
                    style={{
                        marginTop: "-45px",
                        background: "white",
                        position: "absolute",
                        padding: "0 5px 0 5px",

                    }}
                >Invalid Links  </span>
                    
                    {
                        filteredInvalidPapers.length != 0 ?

                            filteredInvalidPapers.map((link, i) =>
                                <span>
                                    <Chip
                                        className="invalid-url"
                                        id={link}
                                        key={i}
                                        label={link}
                                        size="medium"
                                        variant="filled"
                                        color="default"
                                        onDelete={event => deleteLink(event, i)}
                                        style={{
                                            background: "#e0e0e0",
                                            borderRadius: "20px",
                                            margin: "10px 10px 10px 10px",
                                        }}
                                    />
                                </span>
                                
                            )
                    
                        :

                        <div>

                            All Good!
                        </div>

                        
                    }

                </div>


                <div 
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    wrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px 20px"
                    }}
                >

                    <Box sx={{ m: 1, position: 'relative' }}>

                        { invalidAltered ?
                            <Button 
                                id="reset-urls-btn" 
                                onClick={() => reset()} 
                                variant="contained"
                            >
                                Reset
                            </Button>
                            :
                            <React.Fragment/>
                        }

                    </Box>
                    
                        
                </div>
                    
            </div>
            
    )
};

export default InvalidPapers;