import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { render } from '@testing-library/react';
import {useState} from "react";

import httpClient from '../httpClient';

const SubmitPapers = (props) => {

    const [errors, setErrors] = useState("Each link should be on a new line");

    const [isError, setIsErrors] = useState(false);

    const [links, setLinks] = useState("");

    const [busy, setBusy] = useState(false);

    const [label, setLabel] = useState("Enter Arxiv URLs (e.g. arxiv.org/abs/1234.12345)");

    const regex_pattern = new RegExp("(https?://)?arxiv.org/(abs|pdf)/\\d+[.]\\d+(v\\d+)?(.pdf)?");

    const handleSubmit = (event) => {

        event.preventDefault();

        const v = validate();

        setIsErrors(v);

        if (v) {

          return;

        }

        else {

            let urls = links.split('\n');

            urls = urls.map((el) => {

                return el.trim();
            });

            urls = urls.filter(el => el != '');

            urls = urls.join('\n');

            props.submitURLs(urls);

        return;

        }

    }

    const onChange = (event) => {

        let urls = event.target.value;

        let target = event.target;

        setLinks(urls);

        return;
    }

    const validate = () => {

        let x = numLinks();

        if (x > 100) {
            setErrors(
                `A max of 100 links is allowed, you have ${x}`
            )

            return true;
        }

        else if (x == 0) {

            setErrors(
                "No links"
            )

            return true;
        }

        else if (isContainsInvalidLnks()) {
            setErrors(
                "Contains invalids links"
            )

            return true;
        }

        else {
            setErrors(
                "Each link should be on a new line",
            )

            return false;
        }
    }

    const numLinks = () => {

        let x = links.split('\n');

        x = x.filter(el => el.trim().replace('\t', '') != '');

        x = x.length;

        return x;
    }

    const isContainsInvalidLnks = () => {

        let x = links.split('\n');

        for (var i = 0; i < x.length; i++) {

            let possible_url = x[i].trim();

            if (possible_url == '') {
                continue;
            }

            let isMatch = regex_pattern.test(possible_url);

            if (isMatch == false) {

                return true;
            }
        }

        return false;
    }

    const onFocus = () => {

        setLabel("Enter Arxiv URLs")
    }

    const onBlur = () => {

        if (links != '') {

            setLabel("Enter Arxiv URLs");
        }

        else {

            setLabel("Enter Arxiv URLs (e.g. arxiv.org/abs/1234.12345)");
        }

    }

    return (

            <Box 
                component="form" 
                noValidate 
                //onSubmit={handleSubmit} 
                sx={{ mt: 3 }}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "25vw",
                }}
            >

                <TextField
                    classes={{
                        root: {
                            overflowWrap: "visible",
                        }
                    }}
                    multiline
                    rows={4}
                    error={isError}//{firstName.length > 20}
                    helperText={errors}
                    autoComplete="Enter Links"
                    name="links"
                    fullWidth
                    id="links"
                    label={label}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={(event) => onChange(event)}
                    InputLabelProps={{
                        overflow: "wrap"
                    }}
                />

                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button
                    id="submit-urls-btn"
                    variant="contained"
                    //disabled={this.state.busy}
                    onClick={handleSubmit}
                    >
                    Submit
                    </Button>
                </Box>

            </Box>

    )
};

export default SubmitPapers;