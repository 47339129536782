import { Component } from 'react'
import PostList from '../Components/list'
import '../app.scss'
import UserContext from '../Contexts/userContext';
import "react-datepicker/dist/react-datepicker.css";
import httpClient from '../httpClient'

import MyDatePicker from '../Components/desktop_date_picker'
import { Button, CircularProgress } from '@material-ui/core'
import Box from '@mui/material/Box';
import CustomChip from '../Components/chip_custom'
import { SOURCE_ORDERING } from '../constants'
var hash = require('object-hash');

var sourceOrder = SOURCE_ORDERING;


class Recommendations extends Component{
    static contextType = UserContext

    constructor(props) {
        super(props);

        let kwds;

        if (this.props.keywords.length == 0) {
            kwds = [];
        }
        
        else {
            kwds = this.props.keywords.split('\n');
        }

        this.state = {
            posts : this.props.posts,
            busy: false,
            posts2remove: {},
            date_after_filter : 0,
            show_after_date: null,
            date_before_filter : Number.MAX_VALUE,
            show_before_date: null,

            filter_using_keywords: false,
            filter_words: [...kwds],
            show_filter_words: false,

            posts: this.props.posts,
            keywords: [...kwds],

            filtered_posts: this.props.posts,

            type: this.props.type,
        };

        this.changeAfterFilter = this.changeAfterFilter.bind(this);
        this.changeBeforeFilter = this.changeBeforeFilter.bind(this);
        this.editFilter = this.editFilter.bind(this)
        this.toggleKeywordsFilter = this.toggleKeywordsFilter.bind(this);
        this.clearDateFilter = this.clearDateFilter.bind(this);
        this.getInitialData = this.getInitialData.bind(this);

    };


    getInitialData(date_before, date_after, keywords, isFilterKeywords) {

        this.setState({
            busy: true
        });

        let isRecommendations = true;

        if (this.state.type != "recommendations") {
            isRecommendations = false;
        }

        let params = {
            date_before: date_before,
            date_after: date_after,
            keywords: keywords,
            isFilterKeywords: isFilterKeywords,
            page: 0,
            isRecommendations: isRecommendations,
            n_labelled: 0,
        }

        httpClient.get(
            '/api/recommendations_initial',
            {
                params: params
            },
        )
        .then(response => {
                this.setState({
                    filtered_posts: response.data.message,
                    busy: false
                })
            }
        )
        .catch(error => {
                console.error(error)
        })
    }

    changeAfterFilter(date){

        this.setState({
                show_after_date: date,
                date_after_filter: Date.parse(date)
            }
            
        );
        
        if (!this.isValidDate(date)) {

            return;
        }

        this.getInitialData(
            this.state.show_before_date,
            date, 
            this.state.filter_words.toString(),
            this.state.filter_using_keywords,
        );

        return;
    }

    changeBeforeFilter(date){

        this.setState({
                show_before_date: date,
                date_before_filter: Date.parse(date)
            }
        );

        if (!this.isValidDate(date)) {

            return;
        }

        this.getInitialData(
            date,
            this.state.show_after_date,
            this.state.filter_words.toString(),
            this.state.filter_using_keywords,
        );

        return;
    }

    editFilter(event, word){

        var current_words = this.state.filter_words

        if(current_words.indexOf(word) == -1){
            current_words.push(word);
        }
        else {
            current_words.splice(current_words.indexOf(word), 1);
        }

        this.setState(
            {
                filter_words: current_words,
            }
        )

        this.getInitialData(
            this.state.show_before_date,
            this.state.show_after_date,
            current_words.toString(),
            this.state.filter_using_keywords,
        );
    }

    toggleKeywordsFilter() {

        let filter_using_keywords = this.state.filter_using_keywords;

        this.setState((prevState) => ({
                filter_using_keywords: !prevState.filter_using_keywords,
                show_filter_words: !prevState.show_filter_words
            })
        )

        this.getInitialData(
            this.state.show_before_date,
            this.state.show_after_date,
            this.state.filter_words.toString(),
            !filter_using_keywords,
        );        

        return
    }

    clearDateFilter() {
        this.setState({
                date_after_filter : 0,
                show_after_date: null,
                date_before_filter : Number.MAX_VALUE,
                show_before_date: null
            }
        )

        this.getInitialData(
            null,
            null,
            this.state.filter_words.toString(),
            this.state.filter_using_keywords,
        );

        return
    }

    isValidDate(date) {
        
        let x = (
            (date != null)
            &&
            (isNaN(date) == false)
            &&
            (isNaN(date.getFullYear()) == false)
            &&
            (date.getFullYear() > 1970)
        );

        return x ;

    }

    render(){

        return(
            <div>
                    <div>
                        
                        <div 
                            className="container"
                            style={{
                                display: "flex", 
                                flexDirection: "row",
                                flexWrap: "wrap",
                                // Have space between each item
                                justifyContent: "space-around",
                                // Center Vertically
                                alignItems: "center",
                                gap: "30px",

                            }}
                        >
                            <div id="show-date-after">
                                <MyDatePicker
                                    label="Show After" 
                                    onChange={this.changeAfterFilter} 
                                    value={this.state.show_after_date}
                                />
                            </div>
                            
                            <div id="show-date-before">
                                <MyDatePicker 
                                    label="Show Before" 
                                    onChange={this.changeBeforeFilter}
                                    value={this.state.show_before_date}
                                />
                            </div>

                            <Button id="clear_date_filter" onClick={this.clearDateFilter} className="btn--info" variant="contained">Clear Date Filters</Button>
                            <Button id="filter-keywords" onClick={this.toggleKeywordsFilter} className="btn--info" variant="contained">{this.state.show_filter_words ? "Stop Filtering with Keywords" : "Filter Using Keywords"}</Button>

                            {/*
                                <div id="show-date-after" className="setting" style={{width: "25%"}}>
                                Only Show Items From After: <DatePicker id="show-date-after-input" selected={this.state.show_after_date} onChange={this.changeAfterFilter} />
                                </div>
                                <div id="show-date-before" className="setting" style={{width: "25%"}}>
                                    Only Show Items From Before: <DatePicker id="show-date-before-input" selected={this.state.show_before_date} onChange={this.changeBeforeFilter} />
                                </div>
                                <div className="setting" style={{width: "25%"}}>
                                    <button id="clear_date_filter" onClick={this.clearDateFilter} className="btn--info">Clear Date Filters</button>
                                </div>
                                <div className="setting" style={{width: "25%"}}>
                                    <button id="filter-keywords" onClick={this.toggleKeywordsFilter} className="btn--info">{this.state.show_filter_words ? "Stop Filtering with Keywords" : "Filter Using Keywords"}</button>
                                </div>
                            */}
                            
                        </div>
                        {
                            <div className="vertical-center">
                                {
                                    this.state.show_filter_words ?
                                    <div>
                                        <br/>
                                        {this.state.keywords.map((word, index) => 
                                            word === "\n"?
                                            <p></p>
                                            :
                                                
                                            <span 
                                                id={word}
                                                class={
                                                    this.state.filter_words.indexOf(word) > -1 ? 
                                                    "word-filter-btn word--selected" 
                                                    : 
                                                    "word-filter-btn word--unselected"
                                                }
                                            >
                                                <CustomChip 
                                                    key={index}
                                                    word={word} 
                                                    enabled={this.state.filter_words.indexOf(word) > -1}
                                                    editFilter={this.editFilter}
                                                />
                                            </span>
                                        )}
                                        <p></p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        }
                        
                        <div className="container"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: "100px 0px 0px 0px",
                            maxWidth: "100%",
                            padding: "0px 150px 0px 150px"
                        }}
                        >
                            {
                                this.state.busy ?
                                
                                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>

                                :
                            
                                <>
                                {
                                    SOURCE_ORDERING.map( key =>

                                            {
                                                return key in this.state.filtered_posts ?
    
                                                <div 
                                                    className="setting" 
                                                    key={key + "_row"}
                                                >
    
                                                    <PostList
                                                        key={key + "_" + hash(this.state.filtered_posts[key]) + "_posts"}
                                                        source_name={key}
                                                        posts={this.state.filtered_posts[key]}
                                                        addVote={this.addVote}
                                                        removeVote={this.removeVote}
                                                        after_date={this.state.show_after_date}
                                                        before_date={this.state.show_before_date}
                                                        filter_words={this.state.filter_words}
                                                        isFilterKeywords={this.state.filter_using_keywords}
                                                        type={this.props.type}
                                                    />          
    
                                                </div>
    
                                                :
    
                                                <></>
                                            }

                                    )
                                }
                                </>
                            }

                        </div>
                    </div>
            </div>
        )
    }
}

export default Recommendations