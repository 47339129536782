import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import SignIn from './pages/signin'
import SignUp from './pages/signup';
import Recommendations from './pages/recommendations'
import Keywords from './pages/keywords'

import { withRouter } from 'react-router-dom';
import {Link} from 'react-router-dom';

import { useEffect, useState } from 'react';
import httpClient from './httpClient';


const drawerWidth = 240;
const loggedOutNavItems = {
    'About': '/about',
    'Login': '/login',
    'Sign Up': '/sign-up'
};

const loggedInNavItems = {
  'Recommendations': '/recommendations',
  'Likes': '/likes',
  'Papers': '/papers',
  'Settings': '/settings',
  'Keywords': '/keywords',
  'Feedback': null,
  'Logout': '/logout',
}

const newUserNavItems = {
  'Logout': '/logout',
}

const appName = "Up2Date Research"

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navItems,  setNavItems] = React.useState(loggedOutNavItems);

  const [user, setUser] = useState(null);


  useEffect( () => {

    httpClient.get("/auth/@me")
    .then(resp => {
        setUser(resp.data)
        
        if (resp.data == null) {
          setNavItems(loggedOutNavItems);
        }
        
        else {

          if (resp.data.is_new_user) {
            setNavItems(newUserNavItems);
          }

          else {
            setNavItems(loggedInNavItems);
          }
        }

            
        }
    )
    .catch(error => {
        console.error(error);
        setUser(null);
    })
    
  }, [])

  const logout = (e) => {

    localStorage.removeItem("recommendations");
    localStorage.removeItem("settings");
    localStorage.removeItem("keywords");
    localStorage.removeItem("likes");

    httpClient.post("/auth/logout")
    .then(
        //window.location.reload(false),
        setUser(null),
        setNavItems(loggedOutNavItems)

    ).
    catch(error => {
        console.error(error)
    })

  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const func = (page) => {

    if (page == "Logout") {
      logout();
    }

    return;
  }


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {appName}
      </Typography>
      <Divider />
      <List>
        {Object.keys(navItems).map((key, index) => (
          
          key === "Feedback" ?
          
          <ListItem key={key} disablePadding >
            
              <ListItemButton
                href="https://docs.google.com/forms/d/e/1FAIpQLSe4bhQsctWOs5L2MblslEXWWnQrvMnXbI-1gFU_eDkTpUzcUQ/viewform?usp=sf_link" 
                target="_blank"  
                sx={{ textAlign: 'center' }} >
                
                  <ListItemText primary={key} />
                
              </ListItemButton>
            
          </ListItem>
          :
          
          <Link to={navItems[key]} onClick={() => func(key)} style={{color: "inherit", textDecoration: 'none'}}>
          <ListItem key={key} disablePadding >
            
              <ListItemButton sx={{ textAlign: 'center' }} >
                
                  <ListItemText primary={key} />
                
              </ListItemButton>
            
          </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const display1 = {xs: 'block', sm: 'block', md: 'none' };
  const display2 = {xs: 'none', sm: 'none', md: 'block' };

  return (
    <Box id="navbar" sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2,
              display: display1,
            }}
          >
            <MenuIcon sx={{display: display1}}/>
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: display2 }}
          >
            {appName}
          </Typography>
          <Box sx={{ display: display2 }}>
            {Object.keys(navItems).map((key, index) => {

              return (

                key === "Feedback" ?

                  <Button 
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe4bhQsctWOs5L2MblslEXWWnQrvMnXbI-1gFU_eDkTpUzcUQ/viewform?usp=sf_link" 
                    target="_blank" 
                    id={key.toLowerCase()}
                    key={key} 
                    sx={{ "&&" :{color: '#fff' }}} 
                    >
                      {key}
                  </Button>

                :

                <Link to={navItems[key]} onClick={() => func(key)} style={{textDecoration: 'none'}}>

                  <Button 
                    id={key.toLowerCase()}
                    key={key} 
                    sx={{ "&&" :{color: '#fff' }}} 
                    >
                      {key}
                  </Button>

                </Link>
              )
              
                
            })}
          </Box>
        </Toolbar>
      </AppBar>


      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: display1,
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>

      
    </Box>
  );
}

export default DrawerAppBar;
