import React, { useEffect, useState } from 'react';
import SourceList from './pages/sourceList'
import Recommendations from './pages/recommendations'
import Keywords from './pages/keywords'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import './app.scss';
import './Styles/buttons.scss'

import { UserProvider } from './Contexts/userContext';
import httpClient from './httpClient';
import { useNavigate } from "react-router-dom";
import Protect from 'react-app-protect'
import { handleREST } from './handleREST';

import SignIn from './pages/signin'
import SignUp from './pages/signup';
import DrawerAppBar from './navbar';
import { Drawer } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getLocalStorage, save2storage } from './localStorageHelper';
import NotFound from './pages/NotFound';
import MyPapers from './pages/my_papers';
import NewUser from './pages/newUser';
import TextMobileStepper from './Components/stepableViews';
import CardMedia from '@mui/material/CardMedia';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import EmailConfirmation from './pages/EmailConfirmation';
import About from './pages/About';


export default function App() {

    const navigate = useNavigate();

    const [loggedIn, setLoggedIn] = useState(false);

    const [loading, setLoading] = useState(false);

    const [newUser, setNewUser] = useState(false);

    useEffect( () => {

        setLoading(true);

        httpClient.get("/auth/@me")
        .then(resp => {
            setLoggedIn(true);

            if (resp.data.is_new_user) {
                setNewUser(true);
            }

            else {
                setNewUser(false);
            }
        })
        .catch(error => {
            console.error(error);
            setLoggedIn(false);
        })
        .finally(() => {
            setLoading(false);
        })

      }, [loggedIn])

      function Logout() {

        setLoggedIn(false);

        navigate("/");

        window.location.reload(false);
      }
    

      function LoggedInRoutes() {

        return (
            <Routes>
                <Route exact path="/" element={<Recommendation/>}/>
                <Route path="/settings" element={<Setting/>}/>
                <Route path="/keywords" element={<Keyword/>}/>
                <Route path="/recommendations" element={<Recommendation/>}/>
                <Route path="/likes" element={<Likes/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/papers" element={<Papers/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        )
    }
    
    function LoggedOutRoutes() {
    
        return (
            <Routes>
                <Route exact path="/" element={<SignIn/>} />
                <Route exact path="/login" element={<SignIn/>} />
                <Route path="about" element={<About/>}/>
                <Route path="sign-up" element={<SignUp/>}/>
                <Route path="forgot-password" element={<ForgotPassword/>}/>
                <Route path="reset-password" element={<ResetPassword/>}/>
                <Route path="account-confirmation" element={<EmailConfirmation/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        )
    }

    function NewUserRoutes() {

        return (
            <Routes>
                <Route exact path="/" element={<NewUserFunc/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        )
    }

    function NewUserRoutes() {

        return (
            <Routes>
                <Route exact path="/" element={<NewUserFunc/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        )
    }

    return (

            <>
            {
                !loading ?

                <>
                <DrawerAppBar />
                
                {
                    loggedIn ?
                    (
                        newUser ?
                        <NewUserRoutes />
                        :
                        <LoggedInRoutes />
                    )
                    :
                    <LoggedOutRoutes />
                }

                </>

                :

                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
                
                
            }
            </>
        
    );
}

function NewUserFunc() {
    const [sources, setSources] = useState(null);
    const [keywords, setKeywords] = useState("");

    const [complete, setComplete] = useState(false);

    useEffect(()=>{

        httpClient.get(
            '/api/new_user_start'
        ).then(response => {

            setSources(response.data.sources);
            setKeywords(response.data.keywords);
            setComplete(true);

        }).catch(error => {
            console.error(error)
        })

    }, [])

    return (
        complete ? 
        <NewUser 
            sources={sources}
            keywords={keywords}
        /> 
        : 

        <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress />
        </Box>
    );
}

function Recommendation() {
    const [originalPosts, setOriginalPosts] = useState({});
    const [keywords, setKeywords] = useState("");
    const [recommendationsComplete, setRecommendationsComplete] = useState(false);
    const [keywordsComplete, setKeywordsComplete] = useState(false);

    useEffect(()=>{

        let recommendations = getLocalStorage("recommendations");

        if (recommendations == null){

            httpClient.get('/api/recommendations_initial', {
                params: {
                    date_before: null,
                    date_after: null,
                    filter_regex: null,
                    isFilterKeywords: false,
                    page: 0,
                    isRecommendations: true,
                    n_labelled: 0,
                }
            }).then(response => {

                setOriginalPosts(response.data.message);
                save2storage(
                    "recommendations",
                    response.data.message
                );
                setRecommendationsComplete(true);

            }).catch(error => {
                console.error(error)
            })

        }

        else {
            setOriginalPosts(recommendations);
            setRecommendationsComplete(true);
        }

        let kwds = getLocalStorage("keywords");

        if (kwds == null) {

            httpClient.get('/api/keywords').then(response => {
                setKeywords(response.data.message);
                save2storage(
                    "keywords",
                    response.data.message
                );
                setKeywordsComplete(true);
            }).catch(error => {
                console.error(error)
            })
        }

        else {
            setKeywords(kwds);
            setKeywordsComplete(true);
        }
        /*
        save2storage(
            "recommendations",
            originalPosts,
        );

        save2storage(
            "keywords",
            keywords,
        )
        */
        /*
        localStorage.setItem(
            "recommendations",
            JSON.stringify(originalPosts)
        );

        localStorage.setItem(
            "keywords",
            JSON.stringify(keywords)
        );
        */
        
        
    }, [])

    return (
        (recommendationsComplete && keywordsComplete) ? 
        <Recommendations 
            posts={originalPosts}
            keywords={keywords}
            type={"recommendations"}
        /> 
        : 

        <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress />
        </Box>
    );
}


function Likes() {
    const [originalPosts, setOriginalPosts] = useState({});
    const [keywords, setKeywords] = useState("");
    const [likesComplete, setLikesComplete] = useState(false);
    const [keywordsComplete, setKeywordsComplete] = useState(false);

    useEffect(()=>{

        let likes = getLocalStorage("likes");

        if (likes == null){

            httpClient.get('/api/recommendations_initial', {
                params: {
                    date_before: null,
                    date_after: null,
                    filter_regex: null,
                    isFilterKeywords: false,
                    page: 0,
                    isRecommendations: false,
                    n_labelled: 0,
                }
            }).then(response => {

                setOriginalPosts(response.data.message);
                save2storage(
                    "likes",
                    response.data.message
                );
                setLikesComplete(true);

            }).catch(error => {
                console.error(error)
            })

        }

        else {
            setOriginalPosts(likes);
            setLikesComplete(true);
        }

        let kwds = getLocalStorage("keywords");

        if (kwds == null) {

            httpClient.get('/api/keywords').then(response => {
                setKeywords(response.data.message);
                save2storage(
                    "keywords",
                    response.data.message
                );
                setKeywordsComplete(true);
            }).catch(error => {
                console.error(error)
            })
        }

        else {
            setKeywords(kwds);
            setKeywordsComplete(true);
        }
        /*
        save2storage(
            "recommendations",
            originalPosts,
        );

        save2storage(
            "keywords",
            keywords,
        )
        */
        /*
        localStorage.setItem(
            "recommendations",
            JSON.stringify(originalPosts)
        );

        localStorage.setItem(
            "keywords",
            JSON.stringify(keywords)
        );
        */
        
        
    }, [])

    return (
        (likesComplete && keywordsComplete) ? 
        <Recommendations 
            posts={originalPosts}
            keywords={keywords}
            type={"likes"}
        /> 
        : 

        <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress />
        </Box>
    );
}


// Settings Page
function Setting() {
    const [setupComplete, setSetupComplete] = useState(false);
    const [getMessage, setGetMessage] = useState({})
  
    // Request source list
    useEffect(()=>{

        let x = getLocalStorage("settings");

        if (x == null) {

            httpClient.get('/api/user').then(response => {
                setGetMessage(response.data.userInfo)
                save2storage(
                    "settings",
                    response.data.userInfo
                )
                setSetupComplete(true);
            }).catch(error => {
                console.error(error)
            })
        }

        else {
            setGetMessage(
                x
            );
            setSetupComplete(true);
        }

        
    }, [])
    
    // Display all sources with credentials
    return ( 
        <>
        {
            setupComplete ? 
            <>
            {  
                Object.keys(getMessage).length !== 0 ? <SourceList sources={getMessage} feedback={false}/> : <br/>    
            } 
            </> 

            :

            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        }
        </>
        
    );
}

function Keyword() {
    const [setupComplete, setSetupComplete] = useState(false);
    const [getMessage, setGetMessage] = useState({})
    const [keywords, setKeywords] = useState("");
  
    useEffect(()=>{

        let x = getLocalStorage("keywords");

        if (x == null) {

            httpClient.get('/api/keywords').then(response => {
                setGetMessage(response);
                setKeywords(response.data.message);
                save2storage(
                    "keywords",
                    response.data.message
                )
                setSetupComplete(true);
            }).catch(error => {
                console.error(error)
            })
        }

        else {
            setKeywords(x);
            setSetupComplete(true);
        }
        
    }, [])
    
    return (
      <>
          {setupComplete ?
              <React.Fragment>
                    <Keywords
                        keywords={keywords}
                    />
              </React.Fragment>
              :
              <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress />
              </Box>
          }
      </>
  );
}


function Papers() {
    const [papers, setPapers] = useState({});
    const [invalidPapers, setInvalidPapers] = useState("");
    const [papersComplete, setPapersComplete] = useState(false);

    useEffect(()=>{

        let x = getLocalStorage("papers");

        if (x == null){

            httpClient.get('/api/papers_initial', {
                params: {}
            }).then(response => {

                setPapers(response.data.papers);
                setInvalidPapers(response.data.invalid_papers);
                save2storage(
                    "papers",
                    {
                        "papers": response.data.papers,
                        "invalid_papers": response.data.invalid_papers,
                    }
                    
                );
                setPapersComplete(true);

            }).catch(error => {
                console.error(error)
            })

        }

        else {
            setPapers(x.papers);
            setInvalidPapers(x.invalid_papers);
            setPapersComplete(true);
        }
        
        
    }, [])

    return (
        (papersComplete) ? 
        <MyPapers 
            papers={papers}
            invalid_papers={invalidPapers}
        /> 
        : 

        <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress />
        </Box>
    );
}
