

import Keywords from "./keywords";
import SourceList from "./sourceList";
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress } from "@material-ui/core";
import httpClient from "../httpClient";
import LinearProgress from '@mui/material/LinearProgress';
import TextMobileStepper from '../Components/stepableViews';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }


export default function NewUser(props) {

  const [sources, setSources] = React.useState(props.sources);
  const [keywords, setKeywords] = React.useState(props.keywords);
  const [promisesComplete, setPromisesComplete] = React.useState(0);

  React.useEffect( () => {
    
    if (promisesComplete == Object.keys(sources).length) {
      httpClient.get(
          '/api/new_user_complete'
      ).then(
          response => {

          }
      ).catch(
          error => {
              console.error(error)
          }
      )
      .finally(() => {
          window.location.reload(false);
      })
    }
  }, [promisesComplete])

  const steps = [
        {
            "label": "Select Keywords",
            "page": <Keywords keywords={keywords}/>,
            "message": "Please enter, at least, one set of keywords for which you will receive recommendations for"
        },
        {
            "label": "Select Sources",
            "page": <SourceList sources={sources} feedback={null} />,
            "message": "Please select which sources you would like to have enabled (or disabled) below."
        }
  ]

  const gifs = [
    {
        "description": 1,
        "img": 1,
    }
  ]

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const totalSteps = steps.length;
  const [done, setDone] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps;
  };

  const getNewData = () => {
    setLoading(true);

    httpClient.get(
        '/api/new_user_start'
    ).then(response => {

        setSources(response.data.sources);
        setKeywords(response.data.keywords);
        setLoading(false);

    }).catch(error => {
        console.error(error)
    })
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    getNewData();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  getNewData();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();

    if (allStepsCompleted()) {
        setDone(true);
        //handleFinished();
        setTimeout(handleFinished, 5000);
    }

  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleFinished = () => {

    for (const source in sources) {

        httpClient.get(
          '/api/new_user_fetch', {
              params: {
                  source: source,
              }
          }
        ).then(
            reponse => {

            }
        ).catch(
            error => {
                console.error(error)
            }
        )
        .finally(
          () => {
            setPromisesComplete(
              promisesComplete => promisesComplete + 1
            );
          }
        )

    }

    return;

  }

  return (
    <Box sx={{ 
        width: '80vw',
        margin: '0 auto',
        textAlign: 'center',
        borderWidth: 'thick',
        borderColor: '#1976d2',
        borderStyle: 'solid',
        borderRadius: '10px',
        padding: '20px',
        marginBottom: '20px'
    }}>
        
    {
            done ?
            <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    We're Fetching Recommendations for you now! - Hang tight!
                </Typography>

                <LinearProgressWithLabel value={100*promisesComplete/Object.keys(sources).length} />

                <TextMobileStepper />


            </>
            
            :
        <>
          {
            loading ?
            <CircularProgress />
            :
            <>

                <Typography id="title-explanation" variant="h6" gutterBottom>
                    {steps[activeStep].message}
                </Typography>
                <br/>
                {steps[activeStep].page}
                <br/>
                <br/>
                <br/>

            </>
          }
            
        
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {done ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
                We're Fetching Recommendations for you now! - Hang tight!
            </Typography>
            {/*
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
            */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                id="back-btn"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button id="next-btn" onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button id="complete-btn" onClick={handleComplete}>
                    {completedSteps() === totalSteps - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>

      </>
            
    }
    </Box>
  );
}