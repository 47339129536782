import React, { Component } from 'react'
import Post from './post'
import '../app.scss'
//import InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-bidirectional-infinite-scroll'
import httpClient from '../httpClient'

import { List, ListItem, Paper} from '@material-ui/core';
import {Waypoint} from 'react-waypoint';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

var list2uri_pagination = {
    'recommendations': '/api/recommendations_pagination',
    'likes': '/api/recommendations_pagination',
    'papers': '/api/papers_pagination',

}


class PostList extends Component{
    constructor(props){
        super(props);
        this.state = {
            visible: true,
            source_name: this.props.source_name,
            posts: this.props.posts,
            scrollableDiv_name: "scrollableDiv_" + this.props.source_name.split(' ').join('_'),
            ids2delete: [],
            loading: false,
            prevX: 0,
            filter_rules: this.props.filter_rules,
            page: 0,
            moreData: true,
            type: this.props.type,
            noMoreDataMsg: null,
            noPostsMsg: null,
            n_labelled: 0,
        }

        if (this.state.type == "papers") {

            this.state.noMoreDataMsg = "That's all your papers!";
            this.state.noPostsMsg = "You have no saved papers";
        }

        else {

            this.state.noMoreDataMsg = "🎉 You've seen it all! 🎉";
            this.state.noPostsMsg = "🎉 You've seen it all! 🎉"
        }

        /*
        this.addVote = this.addVote.bind(this);
        this.removeVote = this.removeVote.bind(this);
        */
        this.handleProcessLabels = this.handleProcessLabels.bind(this);
        this.getData = this.getData.bind(this);
        this.add_n_labelled = this.add_n_labelled.bind(this);

    }

    /*
    addVote(id, source, label) {

        //var ids2delete = this.state.ids2delete.add(id);

        this.setState({
            ids2delete: this.state.ids2delete.concat(
                id
            )
        });

        this.props.addVote(id, source, label);

    }

    removeVote(id, source) {

        //var ids2delete = this.state.ids2delete.delete(id);

        this.setState({
            ids2delete: this.state.ids2delete.filter(
                el => el !== id
            )
        });

        this.props.removeVote(id, source);

    }
    */

    componentDidMount() {
        // When the component is mounted, add your DOM listener to the "nv" elem.
        // (The "nv" elem is assigned in the render function.)

        //ReactDOM.findDOMNode(this).addEventListener("processLabels", this.handleProcessLabels);
        window.addEventListener("processLabels", this.handleProcessLabels);

        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 0
        };
        
        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);

    }
    
    componentWillUnmount() {
        // Make sure to remove the DOM listener when the component is unmounted.

        //ReactDOM.findDOMNode(this).removeEventListener("processLabels", this.handleProcessLabels);
        window.removeEventListener("processLabels", this.handleProcessLabels);
    }

    compenentDidUpdate(prevProps) {
        if (prevProps.filter_rules !== this.props.filter_rules) {
            /*
            this.setState({
                page: 0
            })
            */

        }
    }


    handleProcessLabels () {
        const ids2delete = this.state.ids2delete;

        const posts = this.state.posts.filter(
            el => (!(ids2delete.includes(el.id)))
        )
    }

    handleObserver(entities, observer) {
        const x = entities[0].boundingClientRect.x;
        
        if (this.state.prevX > x) {
          this.getData();
        }
        this.setState({ prevX: x });

      }

    getData() {

        if (!this.state.moreData) {
            return;
        }

        this.setState({
            loading: true,
        })

        let params;

        if (this.state.type == "papers") {
            params = {
                page: this.state.page + 1,
                n_labelled: this.state.n_labelled,
            }
        }

        else {

            let date_before = this.props.before_date;
            let date_after = this.props.after_date;
            let keywords = this.props.filter_words.toString();
            let isFilterKeywords = this.props.isFilterKeywords;

            let isRecommendations = true;

            if (this.state.type != "recommendations") {
                isRecommendations = false;
            }

            params = {
                date_before: date_before,
                date_after: date_after,
                keywords: keywords,
                isFilterKeywords: isFilterKeywords,
                page: this.state.page + 1,
                source: this.state.source_name,
                isRecommendations: isRecommendations,
                n_labelled: this.state.n_labelled,
            }    
        }

        let uri = list2uri_pagination[this.state.type];

        httpClient.get(
            uri,
            { 
                params: params
            },
        ).then(response => {
            this.setState({
                moreData: response.data.moreData,
                loading: false,
                page: this.state.page + 1,
            })

            if (response.data.message != []) {
                this.setState({
                    posts: this.state.posts.concat(response.data.message),
                })
            }
            
            }

        ).catch(error => {
            console.error(error)
        })

    }


    add_n_labelled(value) {

        this.setState({
            n_labelled: this.state.n_labelled + value
        })
    }

    render(){

        // Additional css
        const loadingCSS = {
            height: "100px",
            margin: "5px"
        };
  
      // To change the loading icon behavior
      const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return(

            <React.Fragment>

                    <div 
                        className="list" 
                        id={"list_" + this.state.source_name}
                        style={{
                            width: '100%',
                            //height: this.state.visible ? '70vh' : '15vh',
                            height: "70vh",
                            margin: "0px 0px 100px 0px"
                        }}
                    
                    >
                        <div className="list__header" style={{height: '10%', marginBottom: '1%'}}>
                            <div className="editable-text">
                                <Typography variant="h4">
                                    {
                                        this.state.source_name === "medium" 
                                        ?
                                        "towards data science"
                                        :
                                        this.state.source_name
                                    
                                    }
                                </Typography>
                            </div>

                            {/* 
                            <div className="editable-text">
                                <span onClick={
                                    () => {this.setState(prevState => ({visible: !prevState.visible}))}}> 
                                    {this.state.visible? "Hide Posts" : "Show Posts"}
                                </span>
                            </div>
                            */}
                            
                        </div>

                        {this.state.visible ?
                            <List className="list__body" id={this.state.scrollableDiv_name}
                            
                                    style={{
                                    overflowX: "scroll",
                                    overflowY: "hidden",
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '85%',
                                    
                                }}>
                                    {this.state.posts.length > 0 ?
                                        <>
                                        {
                                            this.state.posts.map((post, index) => 
                                            <React.Fragment key={index}>

                                                <Post
                                                        key={post.id}
                                                        source_name={this.state.source_name}
                                                        id={post.id}
                                                        title={post.title}
                                                        created_at={post.created_at}
                                                        link={post.link}
                                                        oembed={post.html}
                                                        html={post.html}
                                                        keyword={post.keyword}
                                                        delete={this.delete}
                                                        addVote={this.addVote}
                                                        removeVote={this.removeVote}
                                                        type={this.props.type}
                                                        add_n_labelled={this.add_n_labelled}
                                                />

                                            </React.Fragment>
                                            )
                                        }
                                        </>

                                        :

                                        <Box textAlign="center" style={{margin: "auto"}}>
                                            <Typography>
                                                {this.state.noPostsMsg}
                                            </Typography>
                                        </Box>
                                    }
                            <div
                                ref={loadingRef => (this.loadingRef = loadingRef)}
                                style={loadingCSS}
                            >
                                <Box sx={{ display: 'flex' }} style={loadingTextCSS}>
                                    <CircularProgress />
                                </Box>
                            </div>

                            {
                                ((this.state.moreData === false) && (this.state.posts.length > 0)) ?

                                <span
                                    style={{
                                        whiteSpace: "nowrap",
                                        padding: "0 50px 0 55px",
                                        fontWeight: "bold",
                                        fontSize: "large",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    {this.state.noMoreDataMsg}
                                </span>

                                :

                                <></>
                            }
            
                            </List>
                            : 
                            <></>
                        }
                    </div>
            </React.Fragment>
        )
    }
}

export default PostList;
