import * as React from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function MyDatePicker(props) {
  //const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  /*
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  */

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
            label={props.label}
            inputFormat="MM/dd/yyyy"
            value={props.value}
            onChange={
                (newValue) => {
                    //handleChange(newValue);
                    props.onChange(newValue);
                }
            }
            renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>

  )
}