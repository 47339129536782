import React, { Component } from 'react'
import Source from '../Components/source';
import '../app.scss'
import '../Styles/buttons.scss'
import UserContext from '../Contexts/userContext'
import httpClient from '../httpClient'
import { Button, FormControl, Typography } from '@material-ui/core'
import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';
import Switch from "react-switch";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import objectHash from 'object-hash'
import TextField from '@mui/material/TextField';
import Alert from '../Components/alert'
import { save2storage } from '../localStorageHelper'
  

class SourceList extends Component{
    static contextType = UserContext;

    constructor(props) {
        super(props);

        let errorScreenName = false;
        if ("twitter" in this.props.sources) {
            
            if (this.props.sources["twitter"]["username"] == '') {
                errorScreenName = true;
            }
        }

        this.state = {
            busy: false,
            sources: this.props.sources,
            error: false,
            error_sources: [],
            errorScreenName: errorScreenName,
            value: 0,
            category_map: this.props.sources["arxiv"]["category_map"]
        };

        

        this.saveSettings = this.saveSettings.bind(this);
        this.arxivCategoryClick = this.arxivCategoryClick.bind(this);
        this.updateTwitterUsername = this.updateTwitterUsername.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.returnTabs = this.returnTabs.bind(this);

        this.timeout = 0;
    }

    handleChange(event, newValue){

        this.setState({
            value: newValue
        })

    }

    saveSettings(){

        this.setState({
            busy: true
        })

        localStorage.removeItem("settings");

        httpClient.post('/api/user', this.state.sources).then(response => {

            if(response.data.status !== 'Success'){
                this.setState({
                    errorScreenName: true,
                });

                save2storage(
                    "settings",
                    this.state.sources
                );

                //alert(response.data.status)
            }
            else{
                this.setState({
                    errorScreenName: false,
                });
            }
        })
        .finally(() => {
            this.setState({
                busy: false,
            })
        })

        /* Source May Have Been Disabled, thus making recommendations stale */
        localStorage.removeItem("recommendations")
        
    }

    arxivCategoryClick(event, category) {

        var sources = {...this.state.sources};

        let prev = sources["arxiv"]["categories"][category];

        sources["arxiv"]["categories"][category] = !prev;

        this.setState({
            sources : sources,
            },
            this.saveSettings()
        );

    }

    handleSourceClick(event, source) {

        var sources = {...this.state.sources};

        let enabled = sources[source]["enabled"];

        sources[source]["enabled"] = !enabled;

        this.setState({
            sources : sources
            },
            // Send to server now
            this.saveSettings()
        );

    }

    updateTwitterUsername(event) {

        let sources = this.state.sources;

        sources["twitter"]["username"] = event.target.value.trim();

        this.setState({
            sources: sources
        });

        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {

            let sources = this.state.sources;

            sources["twitter"]["username"] = event.target.value;

            console.log(sources.twitter.username);

            this.setState({
                sources: sources
                },
                this.saveSettings()
            ) 
        }, 3000);

    }

    renderSourceStuff(source) {

        let x;

        let value = this.state.sources[source];

        let y = <></>;

        if (!value["enabled"]) {
            y = (
                <Alert 
                    severity="info" 
                    style={{
                        margin: "0 auto",
                        textAlign: "center"
                    }}
                >
                    {source} data will not be collected or shown in recommendations
                </Alert>    
            )
        }

        if (source == "twitter") {

            x = (
                <div 
                    style={{
                        display: "flex", 
                        flexDirection: "column", 
                        gap: "20px",
                        justifyItems: "center",
                        justifyContent: "center"

                    }}
                >

                <label
                    id={source+"_enabled"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 auto"
                    }}
                >
                    <span>
                    Enable Source?
                    </span>
                    <Switch
                        id={source+"_enabled"}
                        color="primary"
                        checked={value["enabled"]}
                        onChange={event => this.handleSourceClick(event, source)}
                        onColor="#2196f3"
                        label="Enable?"
                        
                    />
                </label>

                    {/* 
                    <input
                        id="twitter-username" 
                        type="text"
                        placeholder="Enter your Twitter screen name"
                        value={value["username"]}
                        onChange={this.updateTwitterUsername}
                        style={{
                            margin: "0 auto"
                        }}
                    />
                    */}
                    

                   <TextField
                        //error={errors.firstName !== ""}//{firstName.length > 20}
                        helperText="Screen Name is your @ handle"
                        autoComplete="Twitter Screen Name"
                        name="screen-name"
                        required
                        fullWidth
                        id="screen-name"
                        label="Twitter Screen Name"
                        value={value["username"]}
                        autoFocus
                        onChange={(event => this.updateTwitterUsername(event))}
                    />

                </div>

            )

        }

        else if (source == "arxiv") {
            x = (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    height: "50vh"
                    }}
                >

                    <label
                        id={source+"_enabled"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            margin: "0 auto"
                        }}
                    >
                        <span>
                        Enable Source?
                        </span>
                        <Switch
                            color="primary"
                            checked={value["enabled"]}
                            onChange={event => this.handleSourceClick(event, source)}
                            onColor="#2196f3"
                            label="Enable?"
                            
                        />
                    </label>

                        
                        <div 
                            style={{
                                display: "flex", 
                                flexDirection: "column", 
                                //overflowY: "scroll",
                                //overflowX: "scroll",
                                overflow: "scroll",
                                maxHeight: "inherit",
                                ustifyContent: "flex-start",
                                padding: "30px",
                                border: "5px solid #000",
                                gap: "5px",
                                borderColor: "gray",
                                borderRadius: "10px",
                                scrollPaddingTop: "20px"

                            }}>
                            <span style={{
                                marginTop: "-45px",
                                background: "white",
                                position: "absolute",
                                padding: "0 5px 0 5px"
                                
                                }}
                            >Enable Categories</span>
                            
                            {
                                Object.entries(value["categories"]).map(([k, v]) =>
                                    <FormControlLabel 
                                        key={k}
                                        control={
                                            <Checkbox 
                                                onChange={event => this.arxivCategoryClick(event, k)}
                                                checked={v} 
                                            />
                                        } 
                                        label={this.state.category_map[k]}  
                                    />
                                    
                                )
                                
                            }
                        
                        </div>

                </div>

            );
        }

        else {

            x = (
                <label
                    id={source+"_enabled"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 auto"
                    }}
                >
                    <span>
                    Enable Source?
                    </span>
                    <Switch
                        color="primary"
                        checked={value["enabled"]}
                        onChange={event => this.handleSourceClick(event, source)}
                        onColor="#2196f3"
                        label="Enable?"
                        
                    />
                </label>
                
                      
                        
            )

        }

        let out = (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                    maxWidth: "50vw",
                    gap: "20px",
                }}
            >
                {y}

                {x}

            </div>
        )

        return out;
    }

    returnTabs() {
        let x = Object.keys(this.state.sources).map( 
            key =>

                {
                    return (
                        <Tab id={key+"-tab-btn"} key={key} label={key === "medium" ? "Towards-Data-Science" : key} />
                    )
                    
                }
        )

        return x;
    }

    render(){

        return(
                <React.Fragment>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "20px"
                        }}
                    >

                    <Box 
                        sx={{ 
                            maxWidth: { xs: 320, sm: 580 }, 
                            bgcolor: 'background.paper' ,
                            //margin: "20px auto 10px auto"
                        }}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >   
                            {this.returnTabs()} 
                            

                            
                        </Tabs>
                    </Box>
                     
                        {
                            this.state.errorScreenName ? 

                                <Alert 
                                    severity="warning" 
                                    style={{
                                        margin: "0 30vw 30px 30vw",
                                        textAlign: "center"
                                    }}
                                >
                                    Your twitter screen name is invalid! No tweets will be collected!
                                </Alert>    
                                
                            
                            :
                            null
                        }
                    <div 
                        className="container" 
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            wrap: "wrap",
                            alignItems: "flex-start",
                            gap: "0px 80px",
                            //height: "50vh"
                        }}>
                        {
                        
                           this.renderSourceStuff(Object.keys(this.state.sources)[this.state.value])

                        }
                    </div>

                        </div>

                        {
                            this.props.feedback ?
                            <Box textAlign='center'>

                                <Button 
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSe4bhQsctWOs5L2MblslEXWWnQrvMnXbI-1gFU_eDkTpUzcUQ/viewform?usp=sf_link"
                                    target="_blank"
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#1976d2",
                                        color: "#fff",
                                        marginTop: "30vh",
                                        marginBottom: "10vh"
                                    }}

                                >
                                    Submit Feedback
                                </Button>

                            </Box>
                            :
                            <></>
                        }
                        
                        
                        
                        
                        
                </React.Fragment>
        )
    }
}

export default SourceList