import { Button } from '@material-ui/core'
import { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../app.scss'

class AddWord extends Component{
    constructor(props) {
        super(props)

        this.state = {
            word: "",
            busy: false,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    add(word){
        this.setState({
            busy: true,
        });
        if (word === '') return
        this.props.add(word);
        this.setState(
            {
                word: "",
                busy: false,
            }
        );
    }

    handleChange(event){
        this.setState({word: event.target.value})
        return
    }

    render(){
        return(

            <div 
                style={{
                    display: "flex",
                    flexDirection: "row",
                    wrap: "wrap",
                    border: "1px solid",
                    padding: "20px",
                    height: "10vh", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    margin: "0 auto",
                    gap: "20px 20px"
                }}
            >
                <input 
                    id="add-keyword-input" 
                    type="text"
                    placeholder="Add new keyword"
                    value={this.state.word === '' ? '' : this.state.word}
                    onChange={this.handleChange}
                    style={{flexBasis: "70%", border: "none", outline: "none"}}
                />
                {
                    this.state.word.length > 0 ?

                    <>  
                        <Box>
                            <Button
                            id="add-keyword-btn"
                            className="btn"
                            onClick={this.add.bind(this, this.state.word)}
                            style={{flexBasis: "15%"}}
                            variant="outlined"
                            >
                                Add
                            </Button>
                            {this.state.busy && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    color: "blue",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                                )}
                        </Box>
                        
                        <Button
                            id="clear-keyword-btn"
                            className="btn"
                            onClick={() => {this.setState({word: ''});}}
                            style={{flexBasis: "15%"}}
                            variant="outlined"
                        >
                            Clear
                        </Button>
                    </>

                    :

                    <></>
                }
                
            </div>
        )
    }
}

export default AddWord;