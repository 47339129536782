const TWITTER = "twitter";

const REDDIT = "reddit";

const YOUTUBE = "youtube";

const PMLR = "pmlr";

const ARXIV = "arxiv";

const MEDIUM = "medium";

const SOURCE_ORDERING = [
    ARXIV,
    PMLR,
    MEDIUM,
    YOUTUBE,
    TWITTER,
    REDDIT,
];

export {TWITTER, REDDIT, YOUTUBE, PMLR, ARXIV, MEDIUM, SOURCE_ORDERING};